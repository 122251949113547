@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400;600;700&display=swap');

:root {
  --manager-gnb-height: 64px;
  --employee-gnb-height: 64px;
  --mobile-employee-gnb-height: 60px;
  --employee-sub-gnb-height: 48px;
}
:focus-visible {
  outline: 0;
}
html {
  background-color: #fafbfc;
}

body {
  -ms-overflow-style: none;
  font-family: 'Pretendard', 'Spartan', sans-serif;
}
.content {
  width: 100%;
  display: flex;
  flex-direction: column;
}

ul {
  list-style: none;
}

svg {
  flex-shrink: 0;
}

@font-face {
  font-family: 'Pretendard';
  font-style: normal;
  font-weight: 400;
  src: url('./resources/fonts/pretendard/Pretendard-Regular.ttf');
}

@font-face {
  font-family: 'Pretendard';
  font-style: normal;
  font-weight: 500;
  src: url('./resources/fonts/pretendard/Pretendard-Medium.ttf');
}

@font-face {
  font-family: 'Pretendard';
  font-style: normal;
  font-weight: 700;
  src: url('./resources/fonts/pretendard/Pretendard-Bold.ttf');
}

@font-face {
  font-family: 'Pretendard';
  font-style: normal;
  font-weight: 800;
  src: url('./resources/fonts/pretendard/Pretendard-ExtraBold.ttf');
}

@font-face {
  font-family: 'Spartan';
  font-style: normal;
  font-weight: 400;
  src: url('./resources/fonts/spartan/Spartan Regular 400.ttf');
}

@font-face {
  font-family: 'Spartan';
  font-style: normal;
  font-weight: 500;
  src: url('./resources/fonts/spartan/Spartan Medium 500.ttf');
}

@font-face {
  font-family: 'Spartan';
  font-style: normal;
  font-weight: 700;
  src: url('./resources/fonts/spartan/Spartan Bold 700.ttf');
}

@font-face {
  font-family: 'Spartan';
  font-style: normal;
  font-weight: 800;
  src: url('./resources/fonts/spartan/Spartan ExtraBold 800.ttf');
}

abbr,
blockquote,
body,
button,
dd,
dl,
dt,
fieldset,
figure,
form,
h1,
h2,
h3,
h4,
h5,
h6,
hgroup,
input,
legend,
li,
ol,
p,
pre,
ul {
  margin: 0;
  padding: 0;
}
